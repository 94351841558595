<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="12">
                <h6 class="mb-25">
                    Nama Role
                </h6>
                <p>{{model.name}}</p>
              </b-col>
              <hr>
              <b-col md="12">
                <h6 class="mb-25">
                    Pilih Regional 
                </h6>
              </b-col>
              <b-col md="12">
                <b-row
                  ref="row"
                  class="mb-1"
                >
                  <b-col md="9">
                    <ValidationProvider name="regional" v-slot="{ errors }">
                      <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                          <multiselect 
                              v-model="selectedRegional"
                              name="idwilayah"
                              label="nmwilayah"
                              track-by="idwilayah" 
                              placeholder="Cari regional"
                              :options="regionalList"
                              class="single-select"
                              :max-height="600"
                              @input="getKantor(selectedRegional.idwilayah)">
                              <span slot="noResult">Maaf regional tidak ditemukan</span>
                          </multiselect>
                          <p class="fs-6 text-danger">{{ errors[0] }}</p>
                      </div>
                  </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <h6 class="mb-25">
                    Pilih Kantor 
                </h6>
              </b-col>
              <b-col md="12">
                <b-row
                  ref="row"
                  class="mb-1"
                >
                  <b-col md="9">
                    <ValidationProvider name="kantor" v-slot="{ errors }">
                      <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                          <multiselect 
                              v-model="selectedKantor"
                              name="nopend"
                              label="namaktr"
                              track-by="nopend" 
                              placeholder="Cari kantor"
                              :options="kantorList"
                              class="single-select"
                              :max-height="600"
                              @input="getPegawai(selectedKantor.nopend)">
                              <span slot="noResult">Maaf kantor tidak ditemukan</span>
                          </multiselect>
                          <p class="fs-6 text-danger">{{ errors[0] }}</p>
                      </div>
                  </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <h6 class="mb-25">
                    NIPPOS  <span class="alert-required">*</span>
                </h6>
              </b-col>
              <b-col md="12">
                <b-row
                  ref="row"
                  class="mb-1"
                >
                  <b-col md="9">
                    <ValidationProvider name="nippos" v-slot="{ errors }" rules="required">
                      <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                        <multiselect 
                            v-model="model.nippos"
                            label="nippos"
                            name="pegawaiList"
                            track-by="nippos" 
                            placeholder="Cari nippos"
                            :options="pegawaiList" 
                            :multiple="true" 
                            :searchable="true" 
                            :clear-on-select="true" 
                            :close-on-select="true" 
                            :custom-label="customLabelNIP"
                            :max-height="600">
                            <span slot="noResult">Maaf nippos tidak ditemukan</span>
                        </multiselect>
                        <p class="fs-6 text-danger">{{ errors[0] }}</p>
                      </div>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-card-footer class="mt-2">
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'manajemen-role' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import Multiselect from 'vue-multiselect';

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    Multiselect,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {
        menus: ''
      },
      showDismissibleAlert: false,
      config: {
        api: "/roles",
        redirect: "manajemen-role",
      },
      menus: [],
      model: [
        {
          role_id: '',
          nippos: []
        }
      ],
      items: [
        {
          nippos: []
        }
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      nippos: [],
      nopend: '',
      selectedRegional: '',
      selectedKantor: '',
      regionalList: [],
      kantorList: [],
      pegawaiList: [],
      statusList: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Tidak Aktif",
          value: 0,
        },
      ],
      messages: "",
      check_position: ""
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getRegional();
  },
  methods: {
    getRegional() {
        let _ = this
        axios.get('/misc/regionals')
        .then(resp=>{
            _.regionalList = resp.data.data
        }) 
    },
    getKantor(id) {
        let _ = this
        axios.get(`/misc/companies/${id}/regional`)
        .then(resp=>{
            _.kantorList = resp.data.data
        }) 
    },
    getPegawai(id) {
      axios
        .get(`/misc/employees?company=${id}`)
        .then(resp => {
          this.pegawaiList = resp.data.data
        })
        .catch(err => console.error(err.response))
    },
    getData() {
      let _ = this;
      axios
        .get(_.config.api + "/" + this.$route.params.id + "/nippos")
        .then((response) => {
          let _ = this;
          _.model = response.data.data;
          _.nippos = response.data.data.nippos;
          // for (const i in _.model.nippos) {
          //   this.nippos.push(_.model.nippos[i].code);
          // }
        });
    },
    customLabelNIP({ nippos, nama, namabagian, descjabatan }) {
      if (nama ==null && namabagian == null && descjabatan == null) {
        return `${nippos}`
      } else if (nama !=null  && namabagian != null && descjabatan != null) {
        return `${nippos} / ${nama} / ${namabagian} / ${descjabatan}`
      } else if (nama == null  && namabagian != null && descjabatan != null) {
        return `${nippos} / ${namabagian} / ${descjabatan}`
      } else if (nama == null && namabagian != null && descjabatan == null) {
        return `${nippos} / ${namabagian}`
      } else if (nama == null && namabagian == null && descjabatan != null) {
        return `${nippos} / ${descjabatan}`
      } else {
        return `${nippos} / ${nama}`
      }
    },
    save() {
      let _ = this;   
      localStorage.setItem('kantor_id', JSON.stringify(_.selectedKantor.nopend))
      let data = {
        role_id: _.$route.params.id,
        nippos: _.model.nippos.map(p => p.nippos??p.code)
      };
      axios
        .post(_.config.api + "/assign-nippos", data)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              event: "success",
              title: "Assign NIPPOS Berhasil",
              text: "NIPPOS berhasil ditambahkan",
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
            vm.errors = e.response.data.data;
          } else {
            vm.messages = e.response.data.message;
            vm.errors = e.response.data.data;
          }
        });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
